import React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import RecruitmentTasksEN from "../modules/Recruitment/components/RecruitmentTasksEN";
import SEO from "../Layout/Seo";
import renderHTML from "../helpers/renderHTML";

const PageSingleRecruitmentENTemplate = props => {
  const recruitment = props.data.wordpressWpRecruitmentEn;

  return (
    <Layout>
      <SEO
        title={recruitment.yoast.title ? recruitment.yoast.title : recruitment.title}
        keywords={recruitment.yoast.metakeywords}
        description={recruitment.yoast.metadesc}
        robots="NOINDEX,FOLLOW"
      />
      <div className="recruitment-wrapper">
        <div className="section__header">
          <h2
            className="section__title--large"
            dangerouslySetInnerHTML={renderHTML(recruitment.title)}
          />
          <h5 className="section__title--subtitle">
            <p>
              Please complete the tasks and send the link to the repository with access (preferred
              Github) to the address&nbsp;
              <a href="mailto:joinpandas@pandagroup.co">joinpandas@pandagroup.co</a>.
            </p>
          </h5>
        </div>
        <section className="recruitment">
          <RecruitmentTasksEN recruitment={recruitment} />
        </section>
      </div>
    </Layout>
  );
};

export default PageSingleRecruitmentENTemplate;

export const pageQuery = graphql`
  query currentRecruitmentEnPageQuery($id: String!) {
    wordpressWpRecruitmentEn(id: { eq: $id }) {
      id
      title
      yoast {
        title
        metakeywords
        metadesc
      }
      acf {
        recruitment_tasks {
          task_additional_text
          task_content
          task_files
          task_title
        }
      }
    }
  }
`;
